import React from "react";
import classes from './WhyTrade.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const cards = [
    {
        title: 'stocks.why_trade.card_01.title',
        text: 'stocks.why_trade.card_01.text',
    },
    {
        title: 'stocks.why_trade.card_02.title',
        text: 'stocks.why_trade.card_02.text',
    },
    {
        title: 'stocks.why_trade.card_03.title',
        text: 'stocks.why_trade.card_03.text',
    },
];

const WhyTrade = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <Fade left>
                        <h2 className={`${classes.title} font-55`}>
                            {t('stocks.why_trade.title')}
                        </h2>
                    </Fade>
                    <Fade right>
                        <h3 className={`${classes.subtitle} font-103`}>
                            {t('stocks.why_trade.subtitle')}
                        </h3>
                    </Fade>
                    <div className="mt-btn">
                        <Button />
                    </div>
                </div>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                    <Fade right key={index}>
                        <div key={index} className={`${classes.card} font-17`}>
                            <div className={classes.arrow}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none">
                                    <rect width="47" height="47" rx="23.5" fill="#6248AF" />
                                    <path d="M9.79175 23.5H37.2084" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M23.5 9.79199L37.2083 23.5003L23.5 37.2087" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className={classes.cardContent}>
                                <h4 className={classes.cardTitle}>
                                    {t(card.title)}
                                </h4>
                                <p className={classes.cardText}>
                                    {t(card.text)}
                                </p>
                            </div>
                        </div>
                    </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default WhyTrade;