import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/AccountsPage/Banner/Banner";
import Faqs from "../components/AccountsPage/Faqs/Faqs";
import Accounts from "../components/AccountsPage/Accounts/Accounts";

const AccountsPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Manson Trade · Accounts</title>
        <meta name="title" content="Manson Trade Account Types - Tailored Solutions for Every Trader" />
        <meta property="og:title" content="Manson Trade Account Types - Tailored Solutions for Every Trader" />
        <meta property="twitter:title" content="Manson Trade Account Types - Tailored Solutions for Every Trader" />

        <meta name="description" content="Choose the perfect account type for your trading style on Manson Trade. Explore our range of accounts, each designed to provide tailored solutions, features, and benefits for every trader." />
        <meta property="og:description" content="Choose the perfect account type for your trading style on Manson Trade. Explore our range of accounts, each designed to provide tailored solutions, features, and benefits for every trader." />
        <meta property="twitter:description" content="Choose the perfect account type for your trading style on Manson Trade. Explore our range of accounts, each designed to provide tailored solutions, features, and benefits for every trader." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Accounts />
        <Faqs />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AccountsPage;
