import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/AboutPage/Banner/Banner";
import Cards from "../components/HomePage/Cards/Cards";
import Guarantees from "../components/AboutPage/Guarantees/Guarantees";
import Awards from "../components/AboutPage/Awards/Awards";
import Support from "../components/AboutPage/Support/Support";
import Faqs from "../components/AboutPage/Faqs/Faqs";

const AboutPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Manson Trade · About Us</title>
        <meta name="title" content="About Manson Trade - Empowering Traders with Innovation and Expertise" />
        <meta property="og:title" content="About Manson Trade - Empowering Traders with Innovation and Expertise" />
        <meta property="twitter:title" content="About Manson Trade - Empowering Traders with Innovation and Expertise" />

        <meta name="description" content="Discover the story behind Manson Trade. Learn about our commitment to innovation, transparency, and empowering traders worldwide. Join a platform that combines expertise with cutting-edge technology." />
        <meta property="og:description" content="Discover the story behind Manson Trade. Learn about our commitment to innovation, transparency, and empowering traders worldwide. Join a platform that combines expertise with cutting-edge technology." />
        <meta property="twitter:description" content="Discover the story behind Manson Trade. Learn about our commitment to innovation, transparency, and empowering traders worldwide. Join a platform that combines expertise with cutting-edge technology." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Cards />
        <Guarantees />
        <Awards />
        <Support />
        <Faqs />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AboutPage;
