import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/AffiliatePage/Banner/Banner";
import EarnWith from "../components/AffiliatePage/EarnWith/EarnWith";
import HowWorks from "../components/AffiliatePage/HowWorks/HowWorks";
import Thinking from "../components/AffiliatePage/Thinking/Thinking";
import YouGet from "../components/AffiliatePage/YouGet/YouGet";

const AffiliatePage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Manson Trade · Affiliate program</title>
        <meta name="title" content="Manson Trade Affiliate Program - Partner with Success, Earn with Every Trade" />
        <meta property="og:title" content="Manson Trade Affiliate Program - Partner with Success, Earn with Every Trade" />
        <meta property="twitter:title" content="Manson Trade Affiliate Program - Partner with Success, Earn with Every Trade" />

        <meta name="description" content="Join the Manson Trade Affiliate Program and unlock a world of opportunities. Partner with success, earn commissions with every trade, and enjoy exclusive benefits. Start building your passive income today!" />
        <meta property="og:description" content="Join the Manson Trade Affiliate Program and unlock a world of opportunities. Partner with success, earn commissions with every trade, and enjoy exclusive benefits. Start building your passive income today!" />
        <meta property="twitter:description" content="Join the Manson Trade Affiliate Program and unlock a world of opportunities. Partner with success, earn commissions with every trade, and enjoy exclusive benefits. Start building your passive income today!" />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <EarnWith />
        <YouGet />
        <HowWorks />
        <Thinking />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AffiliatePage;
