import React from "react";
import classes from './Trade.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/StocksPage/trade.avif';
import Button from '../../GlobalComponents/Button/Button';
import { Fade } from "react-reveal";

const list = [
    'stocks.trade.li_01',
    'stocks.trade.li_02',
    'stocks.trade.li_03',
];

const Trade = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <Fade left>
                        <h2 className={`${classes.title} font-103`}>
                            {t('stocks.trade.title')}
                        </h2>
                    </Fade>
                    <h3 className={`${classes.subtitle} font-55`}>
                        {t('stocks.trade.subtitle')}
                    </h3>
                    <p className={`${classes.text} font-17`}>
                        {t('stocks.trade.text')}
                    </p>
                    <div className={`${classes.btn} mt-btn`}>
                        <Button />
                    </div>
                </div>
                <div className={classes.col}>
                    <ul className={`${classes.list} font-17`}>
                        {list.map((li, index) =>
                            <li key={index}>
                                <svg className={classes.check} xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                    <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#6248AF" />
                                </svg>
                                {t(li)}
                            </li>
                        )}
                    </ul>
                    <img className={classes.image} src={image} alt='' />
                </div>
            </div>
        </section>
    );
};

export default Trade;
