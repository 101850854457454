import React from "react";
import classes from './Effective.module.scss';
import { useTranslation } from "react-i18next";
import icon_01 from '../../../assets/img/FixedPage/Effective/icon_01.svg';
import icon_02 from '../../../assets/img/FixedPage/Effective/icon_02.svg';
import icon_03 from '../../../assets/img/FixedPage/Effective/icon_03.svg';
import { Fade } from "react-reveal";

const cards = [
    {
        image: icon_01,
        title: 'fixed.effective.card_01.title',
        text: 'fixed.effective.card_01.text',
    },
    {
        image: icon_02,
        title: 'fixed.effective.card_02.title',
        text: 'fixed.effective.card_02.text',
    },
    {
        image: icon_03,
        title: 'fixed.effective.card_03.title',
        text: 'fixed.effective.card_03.text',
    },
];

const Effective = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <Fade left>
                    <h2 className={`${classes.title} font-55`}>
                        {t('fixed.effective.title')}
                    </h2>
                </Fade>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <Fade key={index}>
                            <div className={`${classes.card} font-17`}>
                                <img className={classes.icon} src={card.image} alt='' />
                                <h4 className={classes.subtitle}>
                                    {t(card.title)}
                                </h4>
                                <p>
                                    {t(card.text)}
                                </p>
                            </div>
                        </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Effective;
