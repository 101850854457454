import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import image from '../../../assets/img/StocksPage/banner.avif';
import { Fade } from "react-reveal";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <Fade left>
                        <h1 className={`${classes.title} font-103`}>
                            {t('stocks.banner.title')}
                        </h1>
                    </Fade>
                    <Fade right>
                        <h2 className={`${classes.subtitle} font-55`}>
                            {t('stocks.banner.subtitle')}
                        </h2>
                    </Fade>
                    <p className={`${classes.text} font-25`}>
                        {t('stocks.banner.text')}
                    </p>
                    <div className="mt-btn">
                        <Button />
                    </div>
                </div>
                <Fade right>
                    <img className={classes.image} src={image} alt='' />
                </Fade>
            </div>
        </section>
    );
};

export default Banner;
