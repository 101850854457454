import React from "react";
import classes from "./Button.module.scss";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Button = ({ children, login, href, event, white }) => {
  const { t } = useTranslation();
  const { lang } = useParams();

  if (event) {
    return (
      <div
        onClick={event}
        className={`${classes.button} ${white ? classes.white : ''} no-select`}
      >
        {children}
      </div>
    );
  }

  return (
    <Link
      className={`${classes.button} ${white ? classes.white : ''} no-select`}
      to={!href
        ? `${process.env.REACT_APP_CABINET_URL}/${login ? 'login' : 'signup'}?lang=${lang}`
        : `/${lang}/${href}`
      }
    >
      <span>
        {children
          ? children
          : t('btn.start_now')
        }
      </span>
      <div className={classes.arrow}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 24" fill="none">
          <path d="M5.22314 12H19.2231" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12.2231 5L19.2231 12L12.2231 19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
    </Link>
  );
};

export default Button;
