import React, { useState } from "react";
import classes from './Accounts.module.scss';
import { useTranslation } from "react-i18next";

const accounts = [
    {
        title: 'Standard',
        rows: [
            '250 USD',
            'USD EUR GBP JPY MYR IDR ',
            ' 1:1000',
            '0.3',
            'accounts.types.3_per_lot_side',
            'accounts.types.yes',
        ],
    },
    {
        title: 'Premium',
        rows: [
            '10.000 USD',
            'USD EUR GBP JPY MYR IDR',
            ' 1:1000',
            '0.1',
            'accounts.types.3_per_lot_side',
            'accounts.types.yes',
        ],
    },
    {
        title: 'PRO',
        rows: [
            '50.000 USD',
            'USD EUR GBP JPY MYR IDR',
            ' 1:1000',
            '0',
            'accounts.types.no',
            'accounts.types.yes',
        ],
    },
    {
        title: 'VIP',
        rows: [
            '100.000 USD',
            'USD EUR GBP JPY MYR IDR',
            ' 1:3000',
            '0',
            'accounts.types.no',
            'accounts.types.yes',
        ],
    },
];

const rows = [
    'accounts.types.row_01',
    'accounts.types.row_02',
    'accounts.types.row_03',
    'accounts.types.row_04',
    'accounts.types.row_05',
    'accounts.types.row_06',
];

const Accounts = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.body}>
                    <div className={classes.buttons}>
                        {accounts.map((btn, index) =>
                            <div
                                key={index}
                                onClick={() => setActive(index)}
                                className={`${classes.button} ${active === index ? classes.active : ''} no-select`}
                            >
                                <span>
                                    {btn.title}
                                </span>
                                <div className={classes.arrow}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 24" fill="none">
                                        <path d="M5.22314 12H19.2231" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12.2231 5L19.2231 12L12.2231 19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={`${classes.content} font-17`}>
                        {rows.map((row, index) =>
                            <div key={index} className={classes.row}>
                                <p className={classes.rowTitle}>
                                    {t(row)}
                                </p>
                                <p className={classes.rowValue}>
                                    {t(accounts[active].rows[index])}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Accounts;
