import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/HomePage/Banner/Banner";
import Reasons from "../components/HomePage/Reasons/Reasons";
import Reviews from "../components/HomePage/Reviews/Reviews";
import Platform from "../components/HomePage/Platform/Platform";
import Cards from "../components/HomePage/Cards/Cards";
import Modes from "../components/HomePage/Modes/Modes";

const HomePage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Manson Trade</title>
        <meta name="title" content="Manson Trade: Your Gateway to Smart Trading Solutions" />
        <meta property="og:title" content="Manson Trade: Your Gateway to Smart Trading Solutions" />
        <meta property="twitter:title" content="Manson Trade: Your Gateway to Smart Trading Solutions" />

        <meta name="description" content="Explore the Main Page of Manson Trade – a cutting-edge trading platform offering powerful tools, real-time data, and seamless user experience. Dive into the world of smart trading today!" />
        <meta property="og:description" content="Explore the Main Page of Manson Trade – a cutting-edge trading platform offering powerful tools, real-time data, and seamless user experience. Dive into the world of smart trading today!" />
        <meta property="twitter:description" content="Explore the Main Page of Manson Trade – a cutting-edge trading platform offering powerful tools, real-time data, and seamless user experience. Dive into the world of smart trading today!" />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Cards />
        <Modes />
        <Reasons />
        <Reviews />
        <Platform />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default HomePage;
