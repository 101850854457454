import React, { useEffect, useState } from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/FixedPage/banner.avif';
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const list = [
    'fixed.banner.li_01',
    'fixed.banner.li_02',
    'fixed.banner.li_03',
];

const Banner = () => {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section className="container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <Fade left>
                        <h1 className={`${classes.title} font-55`}>
                            {t('fixed.banner.title')}
                        </h1>
                    </Fade>
                    <Fade right>
                        <h2 className={`${classes.subtitle} font-25`}>
                            {t('fixed.banner.subtitle')}
                        </h2>
                    </Fade>
                    {windowWidth <= 480 &&
                        <div className={`${classes.btn} mt-btn`}>
                            <Button />
                        </div>
                    }
                    {windowWidth <= 480 &&
                        <img className={classes.image} src={image} alt='' />
                    }
                    <div className={`${classes.text} font-17`}>
                        <p className={classes.txt}>
                            {t('fixed.banner.text_01')}
                        </p>
                        <ul className={classes.list}>
                            {list.map((li, index) =>
                                <li key={index}>
                                    <svg className={classes.check} xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                        <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#6248AF" />
                                    </svg>
                                    {t(li)}
                                </li>
                            )}
                        </ul>
                        <p className={classes.txt}>
                            {t('fixed.banner.text_02')}
                        </p>
                    </div>
                    {windowWidth > 480 &&
                        <div className={`${classes.btn} mt-btn`}>
                            <Button />
                        </div>
                    }
                </div>
                {windowWidth > 480 &&
                    <Fade right>
                        <img className={classes.image} src={image} alt='' />
                    </Fade>
                }
            </div>
        </section>
    );
};

export default Banner;
