import React, { useState } from "react";
import classes from './Faqs.module.scss';
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

const cards = [
    {
        title: 'fixed.faqs.tab_01.title',
        text: [
            'fixed.faqs.tab_01.text_01',
            'fixed.faqs.tab_01.text_02',
            'fixed.faqs.tab_01.text_03',
        ],
    },
    {
        title: 'fixed.faqs.tab_02.title',
        text: [
            'fixed.faqs.tab_02.text_01',
            'fixed.faqs.tab_02.text_02',
            'fixed.faqs.tab_02.text_03',
            'fixed.faqs.tab_02.text_04',
            'fixed.faqs.tab_02.text_05',
        ],
    },
    {
        title: 'fixed.faqs.tab_03.title',
        text: [
            'fixed.faqs.tab_03.text_01',
            'fixed.faqs.tab_03.text_02',
            'fixed.faqs.tab_03.text_03',
            'fixed.faqs.tab_03.text_04',
            'fixed.faqs.tab_03.text_05',
            'fixed.faqs.tab_03.text_06',
        ],
    },
];

const Faqs = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);

    const cardClick = (value) => value === active
        ? setActive(-1)
        : setActive(value);

    return (
        <section className="container mt">
            <div className={classes.wrap}>
                {cards.map((card, index) =>
                    <Fade key={index} right>
                        <div className={`${classes.card} ${active === index ? classes.active : ''}`}>
                            <div className={classes.head}>
                                <div className={classes.num}>
                                    0{index + 1}
                                </div>
                                <h4 className={`${classes.subtitle} font-22`}>
                                    {t(card.title)}
                                </h4>
                                <div
                                    className={classes.button}
                                    onClick={() => cardClick(index)}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M5 12H19" stroke="#313131" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M12 19L19 12L12 5" stroke="#313131" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div>
                            <div className={`${classes.content} font-17 txt-container`}>
                                {card.text.map((txt, index) =>
                                    <p key={index}>
                                        {t(txt)}
                                    </p>
                                )}
                            </div>
                        </div>
                    </Fade>
                )}
            </div>
        </section>
    );
};

export default Faqs;
