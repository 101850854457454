import React from "react";
import classes from './Support.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/AboutPage/support.avif';
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const Support = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <Fade left>
                        <h2 className={`${classes.title} font-55`}>
                            {t('about.support.title')}
                        </h2>
                    </Fade>
                    <p className={`${classes.text} font-17`}>
                        {t('about.support.text')}
                    </p>
                    <div className="mt-btn">
                        <Button white />
                    </div>
                </div>
                <Fade right>
                    <img className={classes.image} src={image} alt='' />
                </Fade>
            </div>
        </section>
    );
};

export default Support;
