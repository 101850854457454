import React from "react";
import classes from './Cards.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/HomePage/Cards/image_01.webp';
import image_03 from '../../../assets/img/HomePage/Cards/image_03.avif';
import image_04 from '../../../assets/img/HomePage/Cards/image_04.avif';
import { Fade } from "react-reveal";

const cards = [
    {
        classes: classes.card_01,
        image: image_01,
        title: 'home.cards.card_01.title',
        text: 'home.cards.card_01.text',
    },
    {
        classes: classes.card_02,
        title: 'home.cards.card_02.title',
        text: 'home.cards.card_02.text',
    },
    {
        classes: classes.card_03,
        image: image_03,
        title: 'home.cards.card_03.title',
        text: 'home.cards.card_03.text',
    },
    {
        classes: classes.card_04,
        image: image_04,
        title: 'home.cards.card_04.title',
        text: 'home.cards.card_04.text',
    },
];

const Cards = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.wrap}>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <Fade
                            key={index}
                            left={(index === 0 || index === 1) ? true : false}
                            right={(index === 3) ? true : false}
                            bottom={(index === 2) ? true : false}
                        >
                            <div className={`${classes.card} ${card.classes}`}>
                                <div className={classes.content}>
                                    <h4 className={`${classes.title} font-70`}>
                                        {t(card.title)}
                                    </h4>
                                    <p className={`${classes.text} font-17`}>
                                        {t(card.text)}
                                    </p>
                                </div>
                                {card.image &&
                                    <img className={classes.image} src={card.image} alt='' />
                                }
                            </div>
                        </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Cards;
