import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/AccountsPage/Banner/image.webp';
import bg from '../../../assets/img/AccountsPage/Banner/bg.avif';
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.wrap}>
            <div className="container">
                <div className={classes.body}>
                    <div className={classes.content}>
                        <Fade left>
                            <h1 className={`${classes.title} font-55`}>
                                {t('accounts.banner.title')}
                            </h1>
                        </Fade>
                        <Fade right>
                            <h2 className={`${classes.subtitle} font-25`}>
                                {t('accounts.banner.subtitle')}
                            </h2>
                        </Fade>
                        <div className={`${classes.text} font-17 txt-container`}>
                            <p>
                                {t('accounts.banner.text_01')}
                            </p>
                            <p>
                                {t('accounts.banner.text_02')}
                            </p>
                        </div>
                        <div className={`${classes.btn} mt-btn`}>
                            <Button />
                        </div>
                    </div>
                    <Fade right>
                        <img className={classes.image} src={image} alt='' />
                    </Fade>
                </div>
            </div>
            <img className={classes.bg} src={bg} alt='' />
        </section>
    );
};

export default Banner;
