import React, { useState } from "react";
import classes from './Modes.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const cards = [
    {
        title: 'home.modes.tab_01.title',
        text: 'home.modes.tab_01.text',
    },
    {
        title: 'home.modes.tab_02.title',
        text: 'home.modes.tab_02.text',
    },
    {
        title: 'home.modes.tab_03.title',
        text: 'home.modes.tab_03.text',
    },
    {
        title: 'home.modes.tab_04.title',
        text: 'home.modes.tab_04.text',
    },
    {
        title: 'home.modes.tab_05.title',
        text: 'home.modes.tab_05.text',
    },
];

const Modes = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <Fade left>
                    <h2 className={`${classes.title} font-70`}>
                        {t('home.modes.title')}
                    </h2>
                </Fade>
                <h3 className={`${classes.subtitle} font-25`}>
                    {t('home.modes.subtitle')}
                </h3>
                <div className={classes.tabs}>
                    <div className={classes.buttons}>
                        {cards.map((card, index) =>
                            <div 
                                key={index}
                                onClick={() => setActive(index)}
                                className={`${classes.button} ${active === index ? classes.active : ''}`}
                            >
                                <span>
                                    {t(card.title)}
                                </span>
                                <div className={classes.arrow}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 24" fill="none">
                                        <path d="M5.22314 12H19.2231" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12.2231 5L19.2231 12L12.2231 19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={classes.contentWrap}>
                        <div className={classes.content}>
                            <h4 className={`${classes.contentTitle} font-70`}>
                                {t(cards[active].title)}
                            </h4>
                            <p className={`${classes.contentText} font-25`}>
                                {t(cards[active].text)}
                            </p>
                        </div>
                        <div className={classes.btn}>
                            <Button />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Modes;
