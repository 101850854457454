import React from "react";
import classes from './Thinking.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/AffiliatePage/thinking.avif';
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const Thinking = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <Fade left>
                        <h2 className={`${classes.title} font-55`}>
                            <Trans>
                                {t('affiliate.thinking.title')}
                            </Trans>
                        </h2>
                    </Fade>
                    <p className={`${classes.text} font-17`}>
                        {t('affiliate.thinking.text')}
                    </p>
                    <div className="mt-btn">
                        <Button />
                    </div>
                </div>
                <Fade right>
                    <img className={classes.image} src={image} alt='' />
                </Fade>
            </div>
        </section>
    );
};

export default Thinking;
