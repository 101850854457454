import React from "react";
import classes from './Reasons.module.scss';
import { useTranslation } from "react-i18next";
import icon_01 from '../../../assets/img/HomePage/Reasons/icon_01.svg';
import icon_02 from '../../../assets/img/HomePage/Reasons/icon_02.svg';
import icon_03 from '../../../assets/img/HomePage/Reasons/icon_03.svg';
import icon_04 from '../../../assets/img/HomePage/Reasons/icon_04.svg';
import icon_05 from '../../../assets/img/HomePage/Reasons/icon_05.svg';
import icon_06 from '../../../assets/img/HomePage/Reasons/icon_06.svg';
import { Fade } from "react-reveal";

const cards = [
    {
        icon: icon_01,
        title: 'home.reasons.card_01.title',
        text: 'home.reasons.card_01.text',
    },
    {
        icon: icon_02,
        title: 'home.reasons.card_02.title',
        text: 'home.reasons.card_02.text',
    },
    {
        icon: icon_03,
        title: 'home.reasons.card_03.title',
        text: 'home.reasons.card_03.text',
    },
    {
        icon: icon_04,
        title: 'home.reasons.card_04.title',
        text: 'home.reasons.card_04.text',
    },
    {
        icon: icon_05,
        title: 'home.reasons.card_05.title',
        text: 'home.reasons.card_05.text',
    },
    {
        icon: icon_06,
        title: 'home.reasons.card_06.title',
        text: 'home.reasons.card_06.text',
    },
];

const Reasons = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <Fade left>
                    <h2 className={`${classes.title} font-70`}>
                        {t('home.reasons.title')}
                    </h2>
                </Fade>
                <h3 className={`${classes.subtitle} font-25`}>
                    {t('home.reasons.subtitle')}
                </h3>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                    <Fade key={index}>
                        <div className={`${classes.card} font-17`}>
                            <img className={classes.cardIcon} src={card.icon} alt='' />
                            <h4 className={classes.cardTitle}>
                                    {t(card.title)}
                            </h4>
                            <p className={classes.cardText}>
                                {t(card.text)}
                            </p>
                        </div>
                    </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Reasons;
