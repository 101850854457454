import React from "react";
import classes from './WhatIs.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const cards = [
    'fx.what_is.card_01.text',
    'fx.what_is.card_02.text',
];

const WhatIs = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <Fade left>
                        <h2 className={`${classes.title} font-55`}>
                            {t('fx.what_is.title')}
                        </h2>
                    </Fade>
                    <div className={`${classes.text} font-17 txt-container`}>
                        <p>
                            {t('fx.what_is.text_01')}
                        </p>
                        <p>
                            {t('fx.what_is.text_02')}
                        </p>
                    </div>
                    <div className="mt-btn">
                        <Button />
                    </div>
                </div>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <Fade key={index} right>
                            <div className={`${classes.card} font-17`}>
                                <div className={classes.arrow}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none">
                                        <rect width="47" height="47" rx="23.5" fill="#6248AF" />
                                        <path d="M9.79175 23.5H37.2084" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M23.5 9.79199L37.2083 23.5003L23.5 37.2087" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <p className={classes.cardContent}>
                                    <Trans>
                                        {t(card)}
                                    </Trans>
                                </p>
                            </div>
                        </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default WhatIs;