import React, { useState } from "react";
import classes from './Reviews.module.scss';
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import image_01 from '../../../assets/img/HomePage/Reviews/image_01.webp';
import image_02 from '../../../assets/img/HomePage/Reviews/image_02.webp';
import image_03 from '../../../assets/img/HomePage/Reviews/image_03.webp';
import image_04 from '../../../assets/img/HomePage/Reviews/image_04.webp';
import { Fade } from "react-reveal";

const cards = [
    {
        image: image_01,
        subtitle: 'home.reviews.card_01.subtitle',
        text: 'home.reviews.card_01.text',
    },
    {
        image: image_02,
        subtitle: 'home.reviews.card_02.subtitle',
        text: 'home.reviews.card_02.text',
    },
    {
        image: image_03,
        subtitle: 'home.reviews.card_03.subtitle',
        text: 'home.reviews.card_03.text',
    },
    {
        image: image_04,
        subtitle: 'home.reviews.card_04.subtitle',
        text: 'home.reviews.card_04.text',
    },
];

const Reviews = () => {
    const { t } = useTranslation();
    const [swiper, setSwiper] = useState({});
    const [active, setActive] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.head}>
                    <Fade left>
                        <h2 className={`${classes.title} font-70`}>
                            {t('home.reviews.title')}
                        </h2>
                    </Fade>
                    <div className={classes.nav}>
                        <div
                            className={`${classes.navBtn} ${classes.left} ${active === 0 ? classes.inactive : ''}`}
                            onClick={() => swiper.slidePrev()}
                        >
                            <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M5 12H19" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12 5L19 12L12 19" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div
                            className={`${classes.navBtn} ${active === swiper.snapGrid?.length - 1 ? classes.inactive : ''}`}
                            onClick={() => swiper.slideNext()}
                        >
                            <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M5 12H19" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12 5L19 12L12 19" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                </div>
                    <div className={classes.swiperWrap}>
                        <Swiper
                            modules={[Navigation]}
                            className="reviewsSwiper no-select"
                            grabCursor
                            spaceBetween={20}
                            slidesPerView={1}
                            onInit={(e) => { setSwiper(e) }}
                            onSlideChange={(e) => setActive(e.activeIndex)}
                        >
                            {cards.map((card, index) =>
                                <SwiperSlide key={index} className="reviewsSwiperSlide">
                                    <div className={classes.card}>
                                        <div className={classes.image}>
                                            <img src={card.image} alt='' />
                                        </div>
                                        <div className={classes.content}>
                                            <h4 className={`${classes.subtitle} font-25`}>
                                                {t(card.subtitle)}
                                            </h4>
                                            <p className={`${classes.text} font-17`}>
                                                {t(card.text)}
                                            </p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )}
                        </Swiper>
                        <div className={classes.pagination}>
                            {Array.from({ length: 4 }).map((_, index) =>
                                <div 
                                    key={index} 
                                    className={`${classes.bullet} ${active === index ? classes.bulletActive : ''}`}
                                ></div>
                            )}
                        </div>
                    </div>
            </div>
        </section>
    );
};

export default Reviews;
