import React from "react";
import classes from './YouGet.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import image_01 from '../../../assets/img/AffiliatePage/YouGet/image_01.avif';
import image_02 from '../../../assets/img/AffiliatePage/YouGet/image_02.avif';
import image_03 from '../../../assets/img/AffiliatePage/YouGet/image_03.avif';
import image_04 from '../../../assets/img/AffiliatePage/YouGet/image_04.avif';
import image_05 from '../../../assets/img/AffiliatePage/YouGet/image_05.avif';
import image_06 from '../../../assets/img/AffiliatePage/YouGet/image_06.avif';
import { Fade } from "react-reveal";

const YouGet = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={`${classes.grid} ${classes.gridHead}`}>
                    <div className={classes.head}>
                        <Fade left>
                            <h2 className={`${classes.ttl_01} font-55`}>
                                {t('affiliate.you_get.title_01')}
                            </h2>
                        </Fade>
                        <Fade right>
                            <h3 className={`${classes.ttl_02} font-25`}>
                                {t('affiliate.you_get.title_02')}
                            </h3>
                        </Fade>
                        <div className="mt-btn">
                            <Button />
                        </div>
                    </div>
                    <div className={`${classes.card} ${classes.card_01}`}>
                        <div className={classes.content}>
                            <h4 className={`${classes.subtitle} font-40`}>
                                {t('affiliate.you_get.card_01.title')}
                            </h4>
                            <p className={`${classes.text} font-17`}>
                                {t('affiliate.you_get.card_01.text')}
                            </p>
                        </div>
                        <img className={`${classes.image} ${classes.image_01}`} src={image_01} alt='' />
                    </div>
                </div>
                <div className={`${classes.card} ${classes.card_02}`}>
                    <div className={classes.content}>
                        <h4 className={`${classes.subtitle} font-40`}>
                            {t('affiliate.you_get.card_02.title')}
                        </h4>
                        <p className={`${classes.text} font-17`}>
                            {t('affiliate.you_get.card_02.text')}
                        </p>
                    </div>
                    <img className={`${classes.image} ${classes.image_02}`} src={image_02} alt='' />
                </div>
                <div className={classes.grid}>
                    <div className={`${classes.card} ${classes.card_03}`}>
                        <h4 className={`${classes.subtitle} font-40`}>
                            {t('affiliate.you_get.card_03.title')}
                        </h4>
                        <img className={`${classes.image} ${classes.image_03}`} src={image_03} alt='' />
                        <p className={`${classes.text} font-17`}>
                            {t('affiliate.you_get.card_03.text')}
                        </p>
                    </div>
                    <div className={`${classes.card} ${classes.card_04}`}>
                        <h4 className={`${classes.subtitle} font-40`}>
                            {t('affiliate.you_get.card_04.title')}
                        </h4>
                        <img className={`${classes.image} ${classes.image_04}`} src={image_04} alt='' />
                        <p className={`${classes.text} font-17`}>
                            {t('affiliate.you_get.card_04.text')}
                        </p>
                    </div>
                </div>
                <div className={`${classes.card} ${classes.card_05}`}>
                    <div className={classes.content}>
                        <h4 className={`${classes.subtitle} font-40`}>
                            {t('affiliate.you_get.card_05.title')}
                        </h4>
                        <p className={`${classes.text} font-17`}>
                            {t('affiliate.you_get.card_05.text')}
                        </p>
                    </div>
                    <img className={`${classes.image} ${classes.image_05}`} src={image_05} alt='' />
                </div>
                <div className={`${classes.card} ${classes.card_06}`}>
                    <img className={`${classes.image} ${classes.image_06}`} src={image_06} alt='' />
                    <div className={classes.content}>
                        <h4 className={`${classes.subtitle} font-40`}>
                            {t('affiliate.you_get.card_06.title')}
                        </h4>
                        <p className={`${classes.text} font-17`}>
                            {t('affiliate.you_get.card_06.text')}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default YouGet;
