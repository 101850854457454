import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/ForexPage/Banner/Banner";
import WhatIs from "../../components/ForexPage/WhatIs/WhatIs";
import WhyTrade from "../../components/ForexPage/WhyTrade/WhyTrade";

const ForexPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Manson Trade · Trade Forex</title>
        <meta name="title" content="Manson Trade Markets - Diverse Opportunities for Trading Success" />
        <meta property="og:title" content="Manson Trade Markets - Diverse Opportunities for Trading Success" />
        <meta property="twitter:title" content="Manson Trade Markets - Diverse Opportunities for Trading Success" />

        <meta name="description" content="Discover a wide range of markets on Manson Trade – from forex and commodities to stocks and cryptocurrencies. Explore diverse trading opportunities with our intuitive platform." />
        <meta property="og:description" content="Discover a wide range of markets on Manson Trade – from forex and commodities to stocks and cryptocurrencies. Explore diverse trading opportunities with our intuitive platform." />
        <meta property="twitter:description" content="Discover a wide range of markets on Manson Trade – from forex and commodities to stocks and cryptocurrencies. Explore diverse trading opportunities with our intuitive platform." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <WhyTrade />
        <WhatIs />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default ForexPage;
