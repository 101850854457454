import React from "react";
import classes from './Platform.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/HomePage/platform.avif';
import { Fade } from "react-reveal";

const cards = [
    {
        title: 'home.platform.card_01.title',
        text: 'home.platform.card_01.text',
    },
    {
        title: 'home.platform.card_02.title',
        text: 'home.platform.card_02.text',
    },
    {
        title: 'home.platform.card_03.title',
        text: 'home.platform.card_03.text',
    },
];

const Platform = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.image}>
                    <img src={image} alt='' />
                </div>
                <div className={classes.content}>
                    <Fade right>
                        <h2 className={`${classes.title} font-70`}>
                            {t('home.platform.title')}
                        </h2>
                    </Fade>
                    <h3 className={`${classes.subtitle} font-25`}>
                        {t('home.platform.subtitle')}
                    </h3>
                    <p className={`${classes.text} font-17`}>
                        {t('home.platform.text')}
                    </p>
                    <div className={classes.cards}>
                        {cards.map((card, index) =>
                            <Fade key={index} right>
                                <div className={`${classes.card} font-17`}>
                                    <div className={classes.arrow}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none">
                                            <rect width="47" height="47" rx="23.5" fill="#6248AF" />
                                            <path d="M9.79175 23.5H37.2084" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M23.5 9.79199L37.2083 23.5003L23.5 37.2087" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                    <div className={classes.cardContent}>
                                        <h4 className={classes.cardTitle}>
                                            {t(card.title)}
                                        </h4>
                                        <p className={classes.cardText}>
                                            {t(card.text)}
                                        </p>
                                    </div>
                                </div>
                            </Fade>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Platform;
