import React from "react";
import classes from './Cards.module.scss';
import { useTranslation } from "react-i18next";
import icon_01 from '../../../assets/img/CommoditiesPage/Cards/icon_01.svg';
import icon_02 from '../../../assets/img/CommoditiesPage/Cards/icon_02.svg';
import icon_03 from '../../../assets/img/CommoditiesPage/Cards/icon_03.svg';
import { Fade } from "react-reveal";

const cards = [
    {
        icon: icon_01,
        title: 'commodities.cards.card_01.title',
        text: 'commodities.cards.card_01.text',
    },
    {
        icon: icon_02,
        title: 'commodities.cards.card_02.title',
        text: 'commodities.cards.card_02.text',
    },
    {
        icon: icon_03,
        title: 'commodities.cards.card_03.title',
        text: 'commodities.cards.card_03.text',
    },
];

const Cards = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                {cards.map((card, index) =>
                    <Fade key={index}>
                        <div className={`${classes.card} font-17`}>
                            <img className={classes.icon} src={card.icon} alt='' />
                            <h4 className={classes.title}>
                                {t(card.title)}
                            </h4>
                            <p className={classes.text}>
                                {t(card.text)}
                            </p>
                        </div>
                    </Fade>
                )}
            </div>
        </section>
    );
};

export default Cards;
