import React from "react";
import classes from './EarnWith.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/AffiliatePage/earn_with.avif';
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const EarnWith = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <Fade left>
                    <img className={classes.image} src={image} alt='' />
                </Fade>
                <div className={classes.content}>
                    <Fade left>
                        <h2 className={`${classes.title} font-55`}>
                            {t('affiliate.earn_with.title')}
                        </h2>
                    </Fade>
                    <Fade right>
                        <h3 className={`${classes.subtitle} font-25`}>
                            {t('affiliate.earn_with.subtitle')}
                        </h3>
                    </Fade>
                    <div className={`${classes.text} txt-container font-17`}>
                        <p>
                            {t('affiliate.earn_with.text_01')}
                        </p>
                        <p>
                            {t('affiliate.earn_with.text_02')}
                        </p>
                    </div>
                    <div className={`${classes.btn} mt-btn`}>
                        <Button />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EarnWith;
