import HomePage from '../pages/HomePage';
import StocksPage from '../pages/Markets/StocksPage';
import ForexPage from '../pages/Markets/ForexPage';
import CryptoPage from '../pages/Markets/CryptoPage';
import CommoditiesPage from '../pages/Markets/CommoditiesPage';
import FixedPage from '../pages/FixedPage';
import AccountsPage from '../pages/AccountsPage';
import AffiliatePage from '../pages/AffiliatePage';
import AboutPage from '../pages/AboutPage';

export const routes = [
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/markets/stocks', element: StocksPage },
    { path: '/:lang/markets/forex', element: ForexPage },
    { path: '/:lang/markets/crypto-and-metals', element: CryptoPage },
    { path: '/:lang/markets/commodities', element: CommoditiesPage },
    { path: '/:lang/fixed-time-trades', element: FixedPage },
    { path: '/:lang/accounts', element: AccountsPage },
    { path: '/:lang/affiliate-program', element: AffiliatePage },
    { path: '/:lang/about-us', element: AboutPage },
];


export const headerRoutes = [
    { 
        text: 'header.markets',
        routes: [
            { path: '/markets/stocks', text: 'header.markets.stocks' },
            { path: '/markets/forex', text: 'header.markets.forex' },
            { path: '/markets/crypto-and-metals', text: 'header.markets.crypto_and_metals' },
            { path: '/markets/commodities', text: 'header.markets.commodities' },
        ],
    },
    { path: '/fixed-time-trades', text: 'header.fixed_time_trades' },
    { path: '/accounts', text: 'header.accounts' },
    { path: '/affiliate-program', text: 'header.affiliate_program' },
    { path: '/about-us', text: 'header.about_us' },
];
