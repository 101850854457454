import React from "react";
import classes from './Steps.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/StocksPage/steps.avif';
import { Fade } from "react-reveal";

const list = [
    'stocks.steps.li_01',
    'stocks.steps.li_02',
    'stocks.steps.li_03',
];

const Steps = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <Fade left>
                        <h2 className={`${classes.title} font-55`}>
                            <Trans>
                                {t('stocks.steps.title')}
                            </Trans>
                        </h2>
                    </Fade>
                    <h3 className={`${classes.subtitle} font-25`}>
                        {t('stocks.steps.subtitle')}
                    </h3>
                    <ul className={classes.list}>
                        {list.map((li, index) =>
                            <li key={index} className={classes.item}>
                                <div className={classes.num}>
                                    {index + 1}
                                </div>
                                <p className={`${classes.text} font-17`}>
                                    {t(li)}
                                </p>
                            </li>
                        )}
                    </ul>
                </div>
                <Fade right>
                    <img className={classes.image} src={image} alt='' />
                </Fade>
            </div>
        </section>
    );
};

export default Steps;
