import React from "react";
import classes from './Awards.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/AboutPage/Awards/award_01.png';
import image_02 from '../../../assets/img/AboutPage/Awards/award_02.png';
import image_03 from '../../../assets/img/AboutPage/Awards/award_03.png';
import image_04 from '../../../assets/img/AboutPage/Awards/award_04.png';
import { Fade } from "react-reveal";

const cards = [
    image_01,
    image_02,
    image_03,
    image_04,
];

const Awards = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <Fade left>
                    <h2 className={`${classes.title} font-55`}>
                        {t('about.awards.title')}
                    </h2>
                </Fade>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <Fade key={index}>
                            <div className={classes.card}>
                                <img className={classes.image} src={card} alt='' />
                            </div>
                        </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Awards;
