import React from "react";
import classes from './WhyTrade.module.scss';
import { Trans, useTranslation } from "react-i18next";
import icon_01 from '../../../assets/img/ForexPage/WhyTrade/icon_01.svg';
import icon_02 from '../../../assets/img/ForexPage/WhyTrade/icon_02.svg';
import image from '../../../assets/img/ForexPage/WhyTrade/image.avif';
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const WhyTrade = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <Fade right>
                        <h2 className={`${classes.title} font-55`}>
                            <Trans>
                                {t('fx.why_trade.title')}
                            </Trans>
                        </h2>
                    </Fade>
                    <div className={`${classes.btn} mt-btn`}>
                        <Button />
                    </div>
                </div>
                <Fade left>
                    <div className={`${classes.cards} ${classes.cards_01} font-17`}>
                        <div className={`${classes.card} ${classes.card_01}`}>
                            <img className={classes.icon} src={icon_01} alt='' />
                            <h4 className={classes.subtitle}>
                                {t('fx.why_trade.card_01.title')}
                            </h4>
                            <p className={classes.text}>
                                {t('fx.why_trade.card_01.text')}
                            </p>
                        </div>
                        <div className={`${classes.card} ${classes.card_02}`}>
                            <img className={classes.icon} src={icon_02} alt='' />
                            <h4 className={classes.subtitle}>
                                {t('fx.why_trade.card_02.title')}
                            </h4>
                            <p className={classes.text}>
                                {t('fx.why_trade.card_02.text')}
                            </p>
                        </div>
                    </div>
                </Fade>
                <Fade right>
                    <div className={`${classes.cards} ${classes.cards_02} font-17`}>
                        <div className={`${classes.card} ${classes.card_03}`}>
                            <img className={classes.icon} src={icon_01} alt='' />
                            <h4 className={classes.subtitle}>
                                {t('fx.why_trade.card_03.title')}
                            </h4>
                            <p className={classes.text}>
                                {t('fx.why_trade.card_03.text')}
                            </p>
                        </div>
                        <div className={`${classes.card} ${classes.card_04}`}>
                            <img className={classes.icon} src={icon_01} alt='' />
                            <h4 className={classes.subtitle}>
                                {t('fx.why_trade.card_04.title')}
                            </h4>
                            <p className={classes.text}>
                                {t('fx.why_trade.card_04.text')}
                            </p>
                        </div>
                    </div>
                </Fade>
                <img className={classes.image} src={image} alt='' />
            </div>
        </section>
    );
};

export default WhyTrade;
